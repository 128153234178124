import React from 'react';
//import Layout from '../components/layout';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import Pageheader from '../Utils/pageheader';
import SEO from '../components/seo';
import rending from '../images/rendering.jpg';
import ViewPdf from '../Utils/viewPdf';


const NewTemple = () => {
    return (
        <>
            <SEO title="New  Temple" />
            <MDBContainer className="mt-4">
                <h3 className="title font-weight-bolder text-secondary">Our New Temple</h3>
                <Pageheader />
                <MDBRow>
                    <MDBCol md="12" className="mt-4">
                        <h3 className="title font-weight-bold text-info">Directions</h3>
                        <div id="map-container"
                            className="rounded z-depth-1-half map-container"
                            style={{ height: "100%", minHeight: "300px", marginBottom: "50px" }} >
                            <iframe
                                src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJg-BBT1vl5IcRkwtmVri4GEo&key=AIzaSyDTL6n0wwXGh8bAXa2NTLfYCHtLsBKNAcQ"
                                title="Hindu Temple, Cedar Rapids IA"
                                width="100%"
                                height="100%"
                                frameBorder="0"
                                style={{ border: 0 }}
                            />
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol className="mx-auto mt-5">
                        <h4 className="title font-weight-bold text-default"> 3D Rendering</h4>
                        <img src={rending} alt="3d rendering" className="img-fluid" />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol className="mx-auto mt-4">
                        <h4 className="title font-weight-bold text-default"> Site Map</h4>
                        <ViewPdf title="site" pdf='https://iowaht.s3-us-east-2.amazonaws.com/pdf/newtemple/sitemap.pdf' />

                    </MDBCol>
                </MDBRow>
                <MDBRow >
                    <MDBCol className="mx-auto mt-4">
                        <h4 className="title font-weight-bold text-default">Ground Floor</h4>
                        <ViewPdf title="ground" pdf='https://iowaht.s3-us-east-2.amazonaws.com/pdf/newtemple/groundfloor.pdf' />

                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol className="mx-auto mt-4">
                        <h4 className="title font-weight-bold text-default">First Floor</h4>
                        <ViewPdf title="first" pdf='https://iowaht.s3-us-east-2.amazonaws.com/pdf/newtemple/firstfloor.pdf' />

                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol className="mx-auto mt-4">
                        <h4 className="title font-weight-bold text-default">Elevation South West</h4>
                        <ViewPdf title="se" pdf='https://iowaht.s3-us-east-2.amazonaws.com/pdf/newtemple/elevationsw.pdf' />

                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol className="mx-auto mt-4">
                        <h4 className="title font-weight-bold text-default">Elevation North East</h4>
                        <ViewPdf title="ne" pdf='https://iowaht.s3-us-east-2.amazonaws.com/pdf/newtemple/elevationne.pdf' />

                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </>
    );
};

export default NewTemple;